const helpers = {
  getMultiUploadJobTrackedFileIndex(multiUploadJob, filename) {
    return multiUploadJob.files.findIndex(function (element) {
      return (element.fileHandle !== null && element.fileHandle !== undefined && element.fileHandle.name === filename)
    })
  },
  getMultiUploadJobByFilename(state, filename) {
    for (const multiUploadJob of state.jobs) {
      if (multiUploadJob.files) {
        const index = this.getMultiUploadJobTrackedFileIndex(multiUploadJob, filename)
        if (index >= 0) {
          return {
            multiUploadJob: multiUploadJob,
            uploadIndex: index,
          }
        }
      }
    }
    return {
      multiUploadJob: null,
      uploadIndex: -1,
    }
  },
}
export default helpers
