export interface CustomerResponse {
  id: string;
  object: string;
  address: null;
  balance: number;
  created: number;
  currency: null;
  default_source: null;
  delinquent: boolean;
  description: null;
  discount: null;
  email: string;
  invoice_prefix: string;
  invoice_settings: InvoiceSettings;
  livemode: boolean;
  metadata: Metadata;
  name: null;
  next_invoice_sequence: number;
  phone: null;
  preferred_locales: any[];
  shipping: null;
  sources: Sources;
  subscriptions: Sources;
  tax_exempt: string;
  tax_ids: Sources;
}

export interface InvoiceSettings {
  custom_fields: null;
  default_payment_method: string;
  footer: null;
}

export interface Metadata {
}

export interface Sources {
  object: string;
  data: any[];
  has_more: boolean;
  total_count: number;
  url: string;
}

export interface SubscriptionResponse {
  stripeSubscription: StripeSubscriptionClass;
}

export interface StripeSubscriptionClass {
  id: string;
  object: string;
  billing_cycle_anchor: number;
  cancel_at_period_end: boolean;
  canceled_at: number;
  collection_method: string;
  created: number;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  default_payment_method: null;
  default_source: null;
  ended_at: number;
  items: SubscriptionItems;
  latest_invoice: LatestInvoice;
  livemode: boolean;
  metadata: StripeSubscriptionMetadata;
  pending_setup_intent: null;
  plan: Plan;
  quantity: number;
  schedule: null;
  start_date: number;
  status: SubscriptionStatus;
  trial_end: number;
}

// Possible statuses are: trialing, active, incomplete, incomplete_expired, past_due, canceled, unpaid (7)
// How To:   https://stripe.com/docs/billing/subscriptions/overview
// API Docs: https://stripe.com/docs/api/subscriptions/object#subscription_object-status
export class SubscriptionStatus {
  public static readonly Trialing: string = 'trialing';
  public static readonly Active: string = 'active';
  public static readonly Incomplete: string = 'incomplete';
  public static readonly IncompleteExpired: string = 'incomplete_expired';
  public static readonly PastDue: string = 'past_due';
  public static readonly Canceled: string = 'canceled';
  public static readonly Unpaid: string = 'unpaid';
}

// Billing Status is different to stripe and something we defined ourselves in Billing API, it is used to express Rev Cat subscription status
// TODO: get stripe responses to use these statuses too
export class BillingStatus {
  public static readonly Paid: string = 'paid';
  public static readonly Trial: string = 'trial';
  public static readonly BillingIssuesDetected: string = 'billing_issues_detected';
  public static readonly Expired: string = 'expired';
  public static readonly UserUnsubscribed: string = 'user_unsubscribed';
  public static readonly NeverSubscribed: string = 'never_subscribed';
  public static readonly NewUnknownStatus: string = 'new_unknown_status';
}

export class BillingSource {
  // TODO :until a migration happens to have all stripe subscribers use this same billing source set in the token system which rev cat does,
  // the source in the token will always be 'none' or 'apple'.
  // So if it is none, it could in reality be a stripe subscription but that is checked a different way until we unify the methods.
  public static readonly None: string = 'none';
  public static readonly Stripe: string = 'stripe';
  public static readonly Apple: string = 'apple';
}

export interface SubscriptionItems {
  object: string;
  data: SubscriptionItemsDatum[];
  has_more: boolean;
  url: string;
}

export interface SubscriptionItemsDatum {
  id: string;
  object: string;
  created: number;
  metadata: PlanMetadata;
  plan: Plan;
  quantity: number;
  subscription: string;
  tax_rates: any[];
}

export interface PlanMetadata {
}

export interface Plan {
  id: string;
  object: string;
  active: boolean;
  amount: number;
  amount_decimal: number;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: PlanMetadata;
  nickname: string;
  product: string;
  usage_type: string;
}

export interface LatestInvoice {
  id: string;
  object: string;
  account_country: string;
  account_name: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  attempt_count: number;
  attempted: boolean;
  auto_advance: boolean;
  billing_reason: string;
  charge: null;
  collection_method: string;
  created: number;
  currency: string;
  customer: string;
  customer_email: string;
  customer_tax_exempt: string;
  customer_tax_ids: any[];
  default_payment_method: null;
  default_source: null;
  default_tax_rates: any[];
  ending_balance: number;
  hosted_invoice_url: string;
  invoice_pdf: string;
  lines: Lines;
  livemode: boolean;
  metadata: PlanMetadata;
  number: string;
  paid: boolean;
  payment_intent: PaymentIntent;
  period_end: number;
  period_start: number;
  post_payment_credit_notes_amount: number;
  pre_payment_credit_notes_amount: number;
  starting_balance: number;
  status: InvoiceStatus;
  status_transitions: StatusTransitions;
  subscription: string;
  subscription_proration_date: number;
  subtotal: number;
  total_tax_amounts: any[];
  total: number;
  webhooks_delivered_at: number;
}

// Possible invoice statuses are: draft, open, paid, uncollectible, void (5)
// How To:   https://stripe.com/docs/api/invoices/object#invoice_object-status
// API Docs: https://stripe.com/docs/billing/invoices/overview#workflow-overview
export class InvoiceStatus {
  public static readonly Draft: string = 'draft';
  public static readonly Open: string = 'open';
  public static readonly Paid: string = 'paid';
  public static readonly Uncollectible: string = 'uncollectible';
  public static readonly Void: string = 'void';
}

export interface Lines {
  object: string;
  data: LinesDatum[];
  has_more: boolean;
  url: string;
}

export interface LinesDatum {
  id: string;
  object: string;
  amount: number;
  currency: string;
  date: number;
  description: string;
  discountable: boolean;
  livemode: boolean;
  metadata: StripeSubscriptionMetadata;
  period: Period;
  plan: Plan;
  proration: boolean;
  quantity: number;
  subscription: string;
  subscription_item: string;
  type: string;
  unified_proration: boolean;
}

export interface StripeSubscriptionMetadata {
  Environment: string;
  TenantId: string;
}

export interface Period {
  start: number;
  end: number;
}

export interface PaymentIntent {
  id: string;
  object: string;
  amount: number;
  amount_capturable: number;
  amount_received: number;
  application: null;
  canceled_at: number;
  cancellation_reason: string;
  capture_method: string;
  charges: SubscriptionItems;
  client_secret: string;
  confirmation_method: string;
  created: number;
  currency: string;
  customer: string;
  description: string;
  invoice: string;
  livemode: boolean;
  metadata: PlanMetadata;
  on_behalf_of: null;
  payment_method: string;
  payment_method_options: PaymentMethodOptions;
  payment_method_types: string[];
  review: null;
  setup_future_usage: string;
  source: null;
  status: PaymentIntentStatus;
}

// Possible payment intent statuses are: requires_payment_method, requires_confirmation, requires_action, processing, requires_capture, canceled, or succeeded (7)
// How To:   https://stripe.com/docs/payments/intents#intent-statuses
// API Docs: https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status
export class PaymentIntentStatus {
  public static readonly RequiresPaymentMethod: string = 'requires_payment_method';
  public static readonly RequiresConfirmation: string = 'requires_confirmation';
  public static readonly RequiresAction: string = 'requires_action';
  public static readonly Processing: string = 'processing';
  public static readonly RequiresCapture: string = 'requires_capture';
  public static readonly Canceled: string = 'canceled';
  public static readonly Succeeded: string = 'succeeded';
}

export interface PaymentMethodOptions {
  card: Card;
}

export interface Card {
  request_three_d_secure: string;
}

export interface StatusTransitions {
  finalized_at: number;
  voided_at: number;
}

export interface PlanItem {
  tier: string;
  title: string;
  description: string;
  prices: PlanPrice[];
  selectedPrice: PlanPrice;
  features: string[];
  actions: PlanAction[];
}

export class PlanActionTierConditions {
  public static readonly CurrentTierEquals: string = 'currentTierEqualsPlan';
  public static readonly CurrentTierIsHigher: string = 'currentTierIsHigherPlan';
  public static readonly CurrentTierIsLower: string = 'currentTierIsLowerPlan';
}

export interface PlanAction {
  tierCondition: PlanActionTierConditions;
  buttonLabel: string;
  buttonUrl: string;
  isCreatingCheckout?: boolean;
}

export interface PlanPrice {
  interval: string;
  displayPrice: string;
  notes: string;
}

export interface CreateCheckoutRequest {
  UrlParams: {
    tenantId: string;
    email: string;
    successUrl: string;
    cancelUrl: string;
    pricingSet: string;
    interval: string;
    tier: string;
  }
}

export interface CreateCustomerPortalRequest {
  UrlParams: {
    returnUrl: string;
  }
}
