import Vue from 'vue'

import { LibraryCategory } from '@/store/modules/teamlibrary/types'
import { AppConst } from '@/store/modules/constants'

Vue.filter('formatDate', (value: string) => {
  if (value) {
    if (Date.parse(value) === 0) {
      return 'Not Set'
    } else {
      // Convert UTC date value to local timezone date object.
      const localeDate = new Date(value)
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
      // timeStyle short will get rid of second part. timeStyle should be used together with dateStyle.
      const dateOptions: any = { dateStyle: 'short', timeStyle: 'short' }

      // Will return localised date string in "date, time" format. For example: dd/mm/yyyy, hh:mm
      // If you need to get rid of the comma in the output string, get date and time loclae strings separately.
      return localeDate.toLocaleString(undefined, dateOptions)
    }
  } else {
    return 'Not Set'
  }
})

Vue.filter('formatDateShort', (value: string) => {
  if (value) {
    if (Date.parse(value) === 0) {
      return 'Not Set'
    } else {
      // Convert UTC date value to local timezone date object.
      const localeDate = new Date(value)
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
      // Format date into Weedkay, DateMonth, Year order. Result varies according to default locale. For example: Thursday, 26 August 2021
      const options: any = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }

      return localeDate.toLocaleDateString(undefined, options)
    }
  } else {
    return 'Not Set'
  }
})

const thumbnailPlaceHolderImageUrl = '/img/Thumbnail_PlaceholderPro_512.png'
export function hasThumbnailPlaceHolder(value: string): boolean {
  return (value !== undefined && value !== null && value.includes('jig-placeholder'))
}
Vue.filter('hasThumbnailPlaceHolder', (value: any) => hasThumbnailPlaceHolder(value))

export function thumbnailURLMap(value: string): string {
  return thumbnailURLMapCustomType(value)
}
Vue.filter('thumbnailURLMap', (value: any) => thumbnailURLMap(value))

export function thumbnailURLMapCustomType(value: string, fileType: string = 'png'): string {
  if (value) {
    let isPlaceholder = false
    if (value === '/images/jigs/jig-placeholder-color.png' ||
      value === '/images/jig/jig-placeholder-color.png') {
      value = thumbnailPlaceHolderImageUrl
      isPlaceholder = true
    }
    if (fileType !== '') {
      value = value.substr(0, value.lastIndexOf('.'))
      value += '.' + fileType
    }
    // Thumbnails are served from: a static directory (if it's placeholder) or the jig-api, eg: https://api.jig.space.
    // - The placeholder comes from the public/img directory
    // - If it starts with '/images/models/' then it's the old thumbnails
    // - If it starts with '/images/jigs/' then it's the old thumbnails
    // - If it starts with '/library/' then it's the new thumbnails
    // - If it starts with '/tenants/v1/{tenantId}/libary/v1/{itemId}/thumbnail' then it's the new thumbnails served from the jig-api and secured properly (in this case don't set the img src, use the auth-image component)
    if (isPlaceholder) {
      return window.location.origin + value
    } else {
      return AppConst.apiDomain + value
    }
  }
  return ''
}
Vue.filter('thumbnailURLMapCustomType',
  (value: any, fileType: any) => thumbnailURLMapCustomType(value, fileType))

export function categoryIndiciesToCsvString(categoryIndicies: number[], categoriesMap: any): string {
  let categoryNames = ''
  if (categoryIndicies != null && categoriesMap != null) {
    let i = 0
    for (const categoryIndex of categoryIndicies) {
      if (i > 0) {
        categoryNames = categoryNames.concat(', ')
      }
      const category = categoriesMap.get(categoryIndex) as LibraryCategory
      if (category === undefined || category === null) {
        continue
      }
      categoryNames = categoryNames.concat(category.DisplayName)
      i++
    }
  }
  return categoryNames
}

export default function CategoriesArrayToMap(categories: LibraryCategory[]): Map<number, LibraryCategory> {
  const categoriesMap = new Map()
  for (const c of categories) {
    categoriesMap.set(c.CategoryId, c)
  }
  return categoriesMap
}

Vue.filter('categoryIndiciesToCsvString', (values: number[], categoriesMap: Map<number, LibraryCategory>) =>
  categoryIndiciesToCsvString(values, categoriesMap))

export function arrayToCsvString(values: string[], delimiter: string): string {
  let output = ''
  if (values === null) {
    return output
  }
  let i = 0
  for (const v of values) {
    if (v === '') {
      continue
    }
    if (i > 0) {
      output = output.concat(delimiter)
    }
    output = output.concat(v)
    i++
  }
  return output
}

Vue.filter('arrayToCsvString', (values: string[]) => arrayToCsvString(values, ', '))
