import Vue from 'vue'

// If you mistakenly `import from 'vuetify'` you will likely add the entire vuetify.js dist to the bundle which is 1.5MB.
// Using vuetify/lib instead will allow the `vue-cli-service build` to dynamically compile and bundle only the vuetify components we use.
import theme from '@/plugins/theme'
import Vuetify from 'vuetify/lib'

import '@fortawesome/fontawesome-free/css/all.css' // This is also for icons as well as fonts
import '@mdi/font/css/materialdesignicons.css' // All the mdi icons
Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: { light: theme },
    options: { customProperties: true },
  },
})
