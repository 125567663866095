export default {
  primary: '#304DE4',
  secondary: '#EFEFEF',
  tertiary: '#101B31',
  accent: '#82B1FF',
  error: '#F03D3D',
  info: '#0284FE',
  success: '#0CA977',
  warning: '#FFAD0D',
  darkGrey: '#212529',
  anchor: '#5465FC', // default to primary,
}
