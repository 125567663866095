import { getTenantLibraryUploadingUrl, LibraryConstants } from '@/modules/library/constants'
import helpers from '@/modules/library/helpers'
import { getInstance } from '@/plugins/auth0'
import { apiRequest } from '@/store/modules/ApiRequestHelper'
import { AppConst } from '@/store/modules/constants'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Timing as timing } from '@/utils/timing'
import { segmentEventTracking } from '@/utils/tracking'

const tus = require('tus-js-client')

const state = {
  jobs: [], // type MultiUploadJob[]
}

const getters = {
}

const mutations = {
  multiUploadJobCreate(state, file) {
    const uploadFile = {
      fileHandle: file,
      uploadInProgress: false,
      finished: false,
      bytesUploaded: 0,
      bytesTotal: 0,
      uploadProgressPercentage: 0,
      errorMsg: '',
      tusUpload: null,
    }

    const job = {
      id: 0,
      createdByUserId: 0,
      tenantId: TenantHelpers.InvalidTenantID,
      status: '',
      errorMsg: '',
      jobMeta: {},
      tusFileId: '',
      pipelineQueueId: '',
      dateCreated: '',
      dateUpdated: '',
    }
    state.jobs.push({
      index: state.jobs.length,
      job: job,
      files: [uploadFile],
      jobPollingInFlight: false,
    })
  },
  multiUploadJobsReset(state) {
    state.jobs = []
  },
  multiUploadJobSetArchived(state, payload) {
    const multiUploadJob = state.jobs[payload.multiUploadJobIndex]
    multiUploadJob.files = []
    multiUploadJob.job = {
      id: 0,
      createdByUserId: 0,
      tenantId: TenantHelpers.InvalidTenantID,
      status: '',
      errorMsg: '',
      jobMeta: {},
      tusFileId: '',
      pipelineQueueId: '',
      dateCreated: '',
      dateUpdated: '',
    }
    multiUploadJob.jobPollingInFlight = false
  },
  multiUploadJobSetPollingInFlight(state, payload) {
    const multiUploadJob = state.jobs[payload.multiUploadJobIndex]
    multiUploadJob.jobPollingInFlight = payload.jobPollingInFlight
  },
  jobPolledFromServer(state, job) {
    for (const muj of state.jobs) {
      if (muj.job.id === job.id) {
        muj.job = job
        muj.jobPollingInFlight = false
        return
      }
    }
  },
  multiUploadJobUpdateFromServer(state, payload) {
    const multiUploadJob = state.jobs[payload.multiUploadJobIndex]
    multiUploadJob.job = payload.job
  },
  multiUploadJobSetError(state, payload) {
    const multiUploadJob = state.jobs[payload.multiUploadJobIndex]
    multiUploadJob.job.errorMsg = payload.errorMsg
  },
  trackedFileSetTusUpload(state, payload) {
    const multiUploadJob = state.jobs[payload.multiUploadJobIndex]
    const trackedUpload = multiUploadJob.files[payload.trackedFileIndex]
    trackedUpload.tusUpload = payload.tusUpload
  },
  trackedFileUpdateUploadProgress(state, payload) {
    const multiUploadJob = state.jobs[payload.multiUploadJobIndex]
    const trackedUpload = multiUploadJob.files[payload.trackedFileIndex]
    trackedUpload.bytesUploaded = payload.bytesUploaded
    trackedUpload.bytesTotal = payload.bytesTotal
    trackedUpload.uploadProgressPercentage = payload.uploadProgressPercentage
  },
  trackedFileSetError(state, payload) {
    const multiUploadJob = state.jobs[payload.multiUploadJobIndex]
    const trackedUpload = multiUploadJob.files[payload.trackedFileIndex]
    trackedUpload.errorMsg = payload.errorMsg
    trackedUpload.uploadInProgress = false
  },
  trackedFileSetSuccess(state, payload) {
    const multiUploadJob = state.jobs[payload.multiUploadJobIndex]
    const trackedUpload = multiUploadJob.files[payload.trackedFileIndex]
    trackedUpload.finished = payload.result
    trackedUpload.uploadInProgress = false
  },
}

const actions = {
  multiUploadJobsClearAll(context, payload) {
    context.commit('multiUploadJobsReset', payload)
  },
  async pollJobUntilFinalResultAsync(context, multiUploadJobIndex) {
    if (context.rootState.app.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.rootState.app.myTenants[context.rootState.app.myTenantIndex]
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }

    let finished = false
    let pollingReq = false
    let sleepCount = 0
    const initialSleepTimeMs = 2000
    const maxSleepTimeMs = 60000 // 1 minute

    do {
      const multiUploadJob = context.state.jobs[multiUploadJobIndex]
      if (multiUploadJob.job.status === LibraryConstants.JobStatus.Finished || multiUploadJob.job.status === LibraryConstants.JobStatus.Failed || multiUploadJob.jobPollingInFlight) {
        return pollingReq
      }
      context.commit('multiUploadJobSetPollingInFlight', { multiUploadJobIndex: multiUploadJobIndex, jobPollingInFlight: true })

      pollingReq = apiRequest({
        action: AppConst.HTTPAction.GET,
        url: `${LibraryConstants.JobsAPI.jobsV1Url}/${multiUploadJob.job.id}`,
      },
      context,
      'jobPolledFromServer',
      `polling job`,
      AppConst.StoreHelper.loadingModalMutationName,
      {
        [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
      })
      // Wait for the job request to finish..
      await pollingReq

      if (multiUploadJob.job.status === LibraryConstants.JobStatus.Finished || multiUploadJob.job.status === LibraryConstants.JobStatus.Failed) {
        finished = true
      } else {
        // We use an increasing sleep time (to our maximum) so we don't poll too often
        sleepCount++
        const sleepTime = Math.min(initialSleepTimeMs * sleepCount, maxSleepTimeMs)
        await timing.sleep(sleepTime)
      }
    } while (!finished)

    return pollingReq
  },
  multiUploadJobArchive(context, payload) {
    context.commit('multiUploadJobSetArchived', payload)
  },
  uploadFile(context, payload) {
    const fileToUpload = payload.fileToUpload
    const latestDesktopVersionName = payload.latestDesktopVersionName || ''
    const authService = getInstance()

    if (fileToUpload === undefined || fileToUpload === null) {
      return Promise.reject('Invalid fileToUpload')
    }
    const tenantId = this.getMyTenantID(context.rootState.app)
    if (tenantId === TenantHelpers.InvalidTenantID) {
      return Promise.reject('Invalid tenant ID')
    }
    // Create our multi upload job here. Later when we get back the job from the server, it will
    // be added to the multi upload job state.
    context.commit('multiUploadJobCreate', fileToUpload)
    const result = helpers.getMultiUploadJobByFilename(context.state, fileToUpload.name)
    const multiUploadJob = result.multiUploadJob
    if (multiUploadJob === null) {
      return Promise.reject('failed to create MultiUploadJob')
    }
    const trackingEvent = {
      event: 'My3DObjects',
      data: {
        action: 'UploadModel',
      }
    }
    // Upload the actual file via tus
    const upload = new tus.Upload(fileToUpload, {
      // Endpoint is the upload creation URL from your tus server
      endpoint: getTenantLibraryUploadingUrl(tenantId),
      // chunkSize: Infinity,
      // uploadSize: fileToUpload.size,
      // parallelUploads: 1,
      // Retry delays will enable tus-js-client to automatically retry on errors
      retryDelays: [0, 3000, 5000, 10000, 20000],
      // Attach additional meta data about the file for the server
      metadata: {
        filename: fileToUpload.name,
        filetype: 'text/plain',
        'app-version': latestDesktopVersionName,
      },
      headers: {
        Authorization: `Bearer ${authService.accessToken}`,
        [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      },
      removeFingerprintOnSuccess: true, // clear fingerprint in localStorage after successful upload.
      // Callback for errors which cannot be fixed using retries
      onError: function (error) {
        consoleLog('TUS Upload Failed because: ' + error)
        const trackedFileIndex = helpers.getMultiUploadJobTrackedFileIndex(multiUploadJob, fileToUpload.name)
        const errorPayload = {
          multiUploadJobIndex: multiUploadJob.index,
          trackedFileIndex: trackedFileIndex,
          errorMsg: error,
        }
        context.commit('trackedFileSetError', errorPayload)
        segmentEventTracking(trackingEvent.event, { ...trackingEvent.data, label: 'TenantModelUploadErrored', fileName: fileToUpload.name })
      },
      // Callback for reporting upload progress
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
        consoleLog(`TUS Upload in process: ${bytesUploaded}, ${bytesTotal}, ${percentage}%`)
        const trackedFileIndex = helpers.getMultiUploadJobTrackedFileIndex(multiUploadJob, fileToUpload.name)
        let updatePayload = {
          multiUploadJobIndex: multiUploadJob.index,
          trackedFileIndex: trackedFileIndex,
          bytesUploaded: bytesUploaded,
          bytesTotal: bytesTotal,
          uploadProgressPercentage: percentage,
        }
        context.commit('trackedFileUpdateUploadProgress', updatePayload)

        if (bytesUploaded === 0) {
          segmentEventTracking(trackingEvent.event, { ...trackingEvent.data, label: 'TenantModelUploadStarted', fileName: fileToUpload.name })
        }
      },
      // Callback for once the upload is completed
      onSuccess: function () {
        consoleLog(`TUS Upload Success. ${upload.file.name} to ${upload.url}`)
        const trackedFileIndex = helpers.getMultiUploadJobTrackedFileIndex(multiUploadJob, fileToUpload.name)
        let successPayload = {
          multiUploadJobIndex: multiUploadJob.index,
          trackedFileIndex: trackedFileIndex,
          result: true,
        }
        context.commit('trackedFileSetSuccess', successPayload)
        segmentEventTracking(trackingEvent.event, { ...trackingEvent.data, label: 'TenantModelUploadSucceeded', fileName: fileToUpload.name })
      },
      onAfterResponse: function (req, res) {
        if (req.getMethod() === 'POST' && res.getStatus() === 201) {
          // We need to extract the server Job object out of the 201 response from a file upload.
          // So we can use the job to track the pipeline progress.
          const serverJob = JSON.parse(res.getUnderlyingObject().response)
          context.commit('multiUploadJobUpdateFromServer', {
            multiUploadJobIndex: multiUploadJob.index,
            job: serverJob,
          })
        } else if (res.getStatus() > 204) {
          consoleLog(`TUS Upload Job creation failure. Response Status: ${res.getStatus().toString()}`)
          segmentEventTracking(trackingEvent.event, { ...trackingEvent.data, label: 'TenantModelUploadErrored', fileName: fileToUpload.name })
        }
      },
    })

    // Remember the tus upload object so we can pause it if need be.
    context.commit('trackedFileSetTusUpload', {
      multiUploadJobIndex: multiUploadJob.index,
      trackedFileIndex: result.uploadIndex,
      tusUpload: upload,
    })

    // Start the upload
    multiUploadJob.files[result.uploadIndex].uploadInProgress = true
    upload.start()
    return new Promise(() => true)
  },
}

const library = {
  moduleName: 'library',
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default library
